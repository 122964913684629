var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","de":""}},[_c('DxSelectBox',{attrs:{"data-source":[
        { id: 'hours', name: 'Horas' },
        { id: 'days', name: 'Días' },
        { id: 'weeks', name: 'Semanas' },
        { id: 'months', name: 'Meses' },
        { id: 'years', name: 'Años' } ],"value":_vm.select_time,"display-expr":"name","value-expr":"id"},on:{"update:value":function($event){_vm.select_time=$event}}}),_c('v-spacer'),_c('KanbanList',{attrs:{"view":"gantt"},on:{"change_view":function($event){return _vm.$emit('change_view', $event)}}})],1),_c('DxGantt',{attrs:{"task-list-width":500,"height":500,"scale-type":_vm.select_time,"firstDayOfWeek":"1","task-content-template":_vm.taskContentTemplate,"on-context-menu-preparing":_vm.onContextMenuPreparing,"onTaskDblClick":_vm.TaskClick,"onTaskUpdating":_vm.TaskUpdating},on:{"update:scaleType":function($event){_vm.select_time=$event},"update:scale-type":function($event){_vm.select_time=$event}},scopedSlots:_vm._u([{key:"taskContentTemplate",fn:function(ref){
      var item = ref.data;
return [_c('div',{staticClass:"custom-task",class:_vm.getTaskColor(item.taskData.id),style:({ width: item.taskSize.width + 'px' })},[_c('div',{staticClass:"custom-task-img-wrapper"},[_c('UserAvatar',{attrs:{"id_user":_vm.getImagePath(item.taskData.id)}})],1),_c('div',{staticClass:"custom-task-wrapper"},[_c('div',{staticClass:"custom-task-title"},[_vm._v(_vm._s(item.taskData.nombre))])]),_c('div',{staticClass:"custom-task-progress",style:({ width: item.taskData.progress + '%' })})])]}}])},[_c('DxTasks',{attrs:{"data-source":_vm.tasks,"start-expr":"fecha_inicio","end-expr":"fecha_fin","key-expr":"id","progress-expr":"progreso","title-expr":"nombre"}}),_c('DxToolbar',[_c('DxItem',{attrs:{"name":"zoomIn"}}),_c('DxItem',{attrs:{"name":"zoomOut"}})],1),_c('DxEditing',{attrs:{"enabled":true}}),_c('DxValidation',{attrs:{"auto-update-parent-tasks":true}}),_c('DxColumn',{attrs:{"data-field":"nombre","caption":"Tarea"}})],1),(_vm.shw_dialog_task)?_c('v-dialog',{staticClass:"frist_screen",model:{value:(_vm.shw_dialog_task),callback:function ($$v) {_vm.shw_dialog_task=$$v},expression:"shw_dialog_task"}},[_c('TareasFormulario',{attrs:{"id_tarea":_vm.id_task_edit},on:{"close":function($event){return _vm.close_ficha()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }