














































































































import { Component, Vue } from "vue-property-decorator";
import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxColumn,
  DxEditing,
  DxValidation,
  DxToolbar,
  DxItem,
  DxStripLine,
} from "devextreme-vue/gantt";
import DxSelectBox from "devextreme-vue/select-box";
import tareaModule from "@/store/modules/tarea-module";
import UserAvatar from "@/components/Usuarios/UserAvatar.vue";
import TareasFormulario from "@/views/proyectos/tareas/tareasFormulario.vue";
@Component({
  components: {
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
    DxValidation,
    DxToolbar,
    DxItem,
    DxStripLine,
    DxSelectBox,
    UserAvatar,
    TareasFormulario,
    KanbanList: () => import("@/components/TypeView/Kanban_List_Gantt.vue"),
  },
})
export default class TareasGantt extends Vue {
  select_time: string = "weeks";
  shw_dialog_task = false;
  id_task_edit: number = -1;
  created() {
    tareaModule.gettareaes_completoGant();
  }
  get tasks() {
    return tareaModule.tareas_proyecto;
  }

  close_ficha() {
    this.shw_dialog_task = false;
    tareaModule.gettareaes_completoGant();
  }

  TaskClick(e: any) {
    e.cancel = true;
    this.id_task_edit = e.data.id;
    this.shw_dialog_task = true;
  }

  TaskUpdating(e: any) {
    var task = this.tasks.find((x) => x.id === e.values.id)!;
    if (e.newValues.fecha_inicio !== undefined) {
      task.fecha_inicio = e.newValues.fecha_inicio;
    }
    if (e.newValues.fecha_fin !== undefined) {
      task.fecha_fin = e.newValues.fecha_fin;
    }
    task.progreso = e.newValues.progreso;
    tareaModule.modificartarea(task);
  }
  onContextMenuPreparing(e: any) {
    e.cancel = true;
  }
  getImagePath(taskId: any) {
    //API.webApiBaseImages this.tasks.find((x)=>x.id===taskId)!.id_usuario;
    return this.tasks.find((x) => x.id === taskId)!.id_usuario; //"https://localhost:44352/Images/1.png?rnd=2";
  }
  getTaskColor(taskId: any) {
    const color = taskId % 6;
    return `custom-task-color-${color}`;
  }
}
